import * as actionTypes from '../actionTypes';

export const actionChangeLanguage = (lang) => {
  return {
    type: actionTypes.CHANGE_LANGUAGE,
    payload: lang,
  };
};

export const actionToggleLoading = (isLoading) => {
  return {
    type: actionTypes.TOGGLE_LOADING,
    payload: isLoading,
  };
};

export const actionUpdateCmsInfo = (cmsInfo) => {
  return {
    type: actionTypes.UPDATE_CMS_INFO,
    payload: cmsInfo,
  };
};
export const actionSaveListProvinces = (provinces) => {
  return {
    type: actionTypes.SAVE_LIST_PRIVINCES,
    payload: provinces,
  };
};

export const actionSaveLabels = (labels) => {
  return {
    type: actionTypes.SAVE_LABELS,
    payload: labels,
  };
};