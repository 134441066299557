import { combineReducers } from "redux";
import system from "./system";
import userInfo from "./userInfo";
import auth from "./auth";

const rootReducer = combineReducers({
  system,
  userInfo,
  auth
});

export default rootReducer;
