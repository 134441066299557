import CONSTANTS from "../../config/constants";
import * as actionTypes from "../actionTypes";

const initialState = {
    otpReference: null,
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_OTP_REFERENCE:
            return { ...state, otpReference: action.payload};
        default:
            return state;
    }
};

export default auth;