import request from "../utils/request";
import Cookies from "js-cookie";
import ENDPOINT from "../config/endpoints";

const login = (data) => {
    return request({
        url: ENDPOINT.LOGIN,
        method: "post",
        data,
    });
}
//
// const getUserLoginInfo = () => {
//     return request({
//         url: ENDPOINT.LOGIN,
//         method: "get",
//     });
// }

const gennerateOTP = (data) => {
    return request({
        url: ENDPOINT.GENERATE_OTP,
        method: "post",
        data,
    });
}

const regenerateOTP = (data) => {
    return request({
        url: ENDPOINT.REGENERATE_OTP,
        method: "post",
        data,
    });
}

const verifyOTP = (data) => {
    return request({
        url: ENDPOINT.VERIFY_OTP,
        method: "post",
        data,
    });
}

const forgotPassword = (data) => {
    return request({
        url: ENDPOINT.FORGOT_PASSWORD,
        method: "post",
        data,
    });
}

const logoutBE = () => {
    return request({
        url: ENDPOINT.LOGOUT,
        method: "post"
    })
}

const update_login_info = (data) => {
    Cookies.set("login_info", JSON.stringify(data));
}

const login_info = () => {
    try {
        return JSON.parse(Cookies.get("login_info"));
    } catch (_) {
        return {};
    }
}

const logout = async () => {
    try {
        let res = await logoutBE();
        if (+res?.status?.code === 200) {
            Cookies.remove("login_info");
            window.navigatePage("LOGIN");
        } else {
            Cookies.remove("login_info");
            window.navigatePage("LOGIN");
        }
    } catch (e) {
        Cookies.remove("login_info");
        window.navigatePage("LOGIN");
    }
}

export default {
    login,
    update_login_info,
    login_info,
    logout,
    gennerateOTP,
    verifyOTP,
    regenerateOTP,
    forgotPassword
};
