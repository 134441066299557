import {Trans} from "react-i18next";
import {PERMISSIONS} from "../permissions";


export default {
    ROUTERS: [
        {
            name: "ANTISEPTIC_POINT",
            path: "/antiseptic-point",
            element: "AntisepticPointManagement",
            menu: 'METADATA',
            label: <Trans i18nKey="ANTISEPTIC_POINT_MANAGEMENT"/>,
            permission_keys: [
                PERMISSIONS.ADMIN,
                PERMISSIONS.SUPER_ADMIN,
            ]
        },
        {
            name: "FARM",
            path: "/farmSelection",
            element: "FarmManagement",
            menu: 'METADATA',
            label: <Trans i18nKey={"FARM_MANAGEMENT"}/>,
            permission_keys: [
                PERMISSIONS.ADMIN,
                PERMISSIONS.SUPER_ADMIN,
            ]
        },
        {
            name: "DEPARTMENT",
            path: "/department",
            element: "DepartmentManagement",
            menu: 'METADATA',
            label: <Trans i18nKey={"DEPARTMENT_MANAGEMENT"}/>,
            permission_keys: [
                PERMISSIONS.ADMIN,
                PERMISSIONS.SUPER_ADMIN,
            ]
        },
        {
            name: "REGION",
            path: "/regionData",
            element: "RegionManagement",
            menu: 'METADATA',
            label: <Trans i18nKey={"REGION_MANAGEMENT"}/>,
            permission_keys: [
                PERMISSIONS.ADMIN,
                PERMISSIONS.SUPER_ADMIN,
            ]
        },
        {
            name: "STAFF",
            path: "/staff",
            element: "StaffManagement",
            label:"Staff",
            menu:"USER",
            permission_keys: [
                PERMISSIONS.ADMIN,
                PERMISSIONS.SUPER_ADMIN,
            ]
        },
        {
            name: "ARTICLE",
            path: "/article",
            element: "ArticleManagement",
            label: <Trans i18nKey={"ARTICLE_MANAGEMENT"}/>,
            menu: 'NEWSPAPER',
            permission_keys: [
                PERMISSIONS.ADMIN,
                PERMISSIONS.SUPER_ADMIN,
            ]
        },
        {
            name: "CATEGORY",
            path: "/article-category",
            element: "CategoryManagement",
            label: <Trans i18nKey={"CATEGORY_MANAGEMENT"}/>,
            menu: 'NEWSPAPER',
            permission_keys: [
                PERMISSIONS.ADMIN,
                PERMISSIONS.SUPER_ADMIN,
            ]
        },

        //checkin
        {
            name: "CHECKIN_HISTORIES",
            path: "/checkin-histories",
            element: "CheckinHistories",
            label: <Trans i18nKey={"CHECKIN_HISTORY"}/>,
            menu: 'CHECKIN',
            permission_keys: [
                PERMISSIONS.ADMIN,
                PERMISSIONS.SUPER_ADMIN,
                PERMISSIONS.MANAGER,
            ]
        },
        {
            name: "ANTISEPTIC_POINT_CHECKIN",
            path: "/antiseptic-point-checkin",
            element: "AntisepticPointCheckin",
            label: <Trans i18nKey={"ANTISEPTIC_POINT_CHECKIN"}/>,
            menu: 'CHECKIN',
            permission_keys: [
                PERMISSIONS.ADMIN,
                PERMISSIONS.SUPER_ADMIN,
                PERMISSIONS.MANAGER,
            ]
        },
        {
            name: "NEW_REGISTER_REPORT",
            path: "/new-register-report",
            element: "NewRegisterReport",
            label: "User register",
            menu: 'USER',
            permission_keys: [
                PERMISSIONS.ADMIN,
                PERMISSIONS.SUPER_ADMIN,
            ]
        },
        // {
        //     name: "DELETED_ACCOUNT",
        //     path: "/deleted-account",
        //     element: "DeletedAccount",
        //     label: "Deleted account",
        //     menu: 'USER',
        //     permission_keys: [
        //         PERMISSIONS.ADMIN,
        //         PERMISSIONS.SUPER_ADMIN,
        //     ]
        // },

        //bran
        {
            name: "LIST_BRAN",
            path: "/bran",
            element: "BranManagement",
            label: "List bran",
            menu: 'BRAN',
            permission_keys: [
                PERMISSIONS.ADMIN,
                PERMISSIONS.SUPER_ADMIN,
            ]
        },
        {
            name: "BRAN_SCAN_HISTORY",
            path: "/bran-scan-history",
            element: "BranScanHistory",
            label: "Bran scan history",
            menu: 'BRAN',
            permission_keys: [
                PERMISSIONS.ADMIN,
                PERMISSIONS.SUPER_ADMIN,
                PERMISSIONS.MANAGER,
            ]
        },
        {
            name: "BRAN_USAGE_REPORT",
            path: "/bran-usage-report",
            element: "BranUsageReport",
            label: "Usage Report",
            menu: 'BRAN',
            permission_keys: [
                PERMISSIONS.ADMIN,
                PERMISSIONS.SUPER_ADMIN,
                PERMISSIONS.MANAGER
            ]
        },
        // {
        //     name: "CONTACT",
        //     path: "/contact",
        //     element: "ContactManagement",
        //     permission_keys: [
        //         PERMISSIONS.ADMIN,
        //         PERMISSIONS.SUPER_ADMIN,
        //     ]
        // },
    ]
}