import React, {useState} from "react";
import './css/index.scss'
import {Avatar, Button, Divider, Dropdown, Modal, Space} from 'antd'
import {useTranslation} from "react-i18next";
import {ExclamationCircleFilled, LockOutlined, LogoutOutlined, UserOutlined} from "@ant-design/icons";
import authServices from "../../services/auth";
import {useDispatch, useSelector} from "react-redux";
import ModalChangePassword from "../../pages/admin/changePassword/ModalChangePassword";

const AdminHeader = (props) => {
    const {
        title = '',
        total = null,
        actions = [],
        isModal = false
    } = props;
    const {t} = useTranslation();
    const onClick = (item) => {
        if (item.key === 'logout') {
            Modal.confirm({
                title: 'Confirm',
                icon: <ExclamationCircleFilled/>,
                content: 'Are you sure you want to sign out?',
                onOk() {
                    authServices.logout();
                },
                onCancel() {
                },
            });
        }
        if (item?.key === "changePassword") {
            setOpenModalChangePassword(true);
        }
    }
    const dispatch = useDispatch();
    const loginInfo = useSelector((state) => state.userInfo.loginInfo);
    const cmsInfo = useSelector((state) => state.system.cmsInfo);
    const locale = useSelector(state => state.system.locale);
    const [isOpenModalChangePassword, setOpenModalChangePassword] = useState(false)
    const items = [
        {
            key: 'changePassword',
            label: 'Change password',
            icon:<LockOutlined/>
        },
        {
            key: 'logout',
            label: 'Sign out',
            icon: <LogoutOutlined/>
        },
    ]
    return (
        <div className={isModal ? "modal-header flex justify-content-between" : "admin-header"}>
            <div className={isModal ? "modal-title" : "admin-header__left"}>
                {
                    !isModal ?
                        <h5 className="admin-header__left--title">
                            {title}
                        </h5>
                        :
                        <h6 className='text-medium'>
                            {title}
                        </h6>
                }
                {![null, undefined, ''].includes(total) ?
                    <p className="admin-header__left--subtitle">
                        {t("TOTAL")}: {total}
                    </p>
                    : null}
            </div>
            {
                !isModal ?
                    <div className="admin-header__right">
                        <Space split={<Divider type={"vertical"}/>}
                               className='admin-layout-header__right flex align-items-center gap-xl'>
                            {/*<Dropdown*/}
                            {/*    menu={{items: languageitems, onClick: handleChangeLanguage}}*/}
                            {/*    placement="topCenter"*/}
                            {/*    trigger={['click']}*/}
                            {/*    arrow={{*/}
                            {/*        pointAtCenter: true,*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <Button*/}
                            {/*        className='flex align-items-center'*/}
                            {/*        icon={locale === "vi" ? <img src={viIcon} alt={"vi"} width={'20px'}/> :*/}
                            {/*            <img src={enIcon} alt={"en"} width={'20px'}/>}*/}
                            {/*    >*/}
                            {/*        {*/}
                            {/*            locale === "vi" ? t("VIETNAMESE") : t("ENGLISH")*/}
                            {/*        }*/}
                            {/*    </Button>*/}
                            {/*</Dropdown>*/}
                            <div className='flex align-items-center' style={{cursor: 'pointer'}}>
                                <Dropdown menu={{items, onClick}} placement="topRight" trigger={['click']}>
                                    <div
                                        className='flex align-items-center gap-xs text-center'
                                    >
                                        <Avatar style={{
                                            backgroundColor:'#FBAB7E',
                                            backgroundImage:'linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)'
                                        }} size={32} icon={<UserOutlined/>}/>
                                        <div className='user-infor flex flex-column'>
                                            {/*<span className='p-xs'>{loginInfo?.name || loginInfo?.email || loginInfo?.phone}</span>*/}
                                            <span style={{lineHeight: '16px'}}
                                                  className='text-xs'>{loginInfo?.name || loginInfo?.email || loginInfo?.phone}</span>
                                            <span style={{lineHeight: '16px'}}
                                                  className='text-primary-color text-2xs'>{loginInfo?.user?.role}</span>
                                        </div>
                                    </div>
                                </Dropdown>
                            </div>
                        </Space>
                    </div>
                    :
                    <Space size={[8, 8]}>
                        {
                            actions?.filter((a) => !a.hide).map((a) =>
                                <Button
                                    key={a.key}
                                    type={a.type}
                                    disabled={a.disabled}
                                    onClick={() => a.click()}
                                    icon={a.icon}
                                    ghost={a.ghost}
                                    loading={a?.loading}
                                >
                                    {a.label}
                                </Button>
                            )
                        }
                    </Space>
            }
            {
                isOpenModalChangePassword &&
                <ModalChangePassword
                    username={loginInfo?.user?.username}
                    open={isOpenModalChangePassword}
                    onCancel={() => setOpenModalChangePassword(false)}
                    onSuccess={() => setOpenModalChangePassword(false)}
                />
            }
        </div>
    );
}

export default AdminHeader;