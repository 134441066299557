import {useEffect, useState} from 'react'

import {LoadingOutlined, UploadOutlined} from "@ant-design/icons";

import {Button, Spin, Upload} from 'antd'
import './uploadImage.scss'
import {actionUploadImage} from './action';
import {message} from "antd/es/index";

const
    UploadImage = (props) => {
        const {image, onCropSuccess, onUploadSuccess} = props;
        const [cropData, setCropData] = useState(image || null);
        const [loading, setLoading] = useState(false);
        const [imageUrl, setImageUrl] = useState();
        const [isFetching, setFetching] = useState(false);

        useEffect(() => {
            setImageUrl(image);
        }, [image]);

        const beforeUpload = (file) => {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                message.error('You can only upload JPG/PNG file!');
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error('Image must smaller than 2MB!');
            }
            return isJpgOrPng && isLt2M;
        };

        // const getBase64 = (img, callback) => {
        //     const reader = new FileReader();
        //     reader.addEventListener('load', () => {
        //         console.log('reader', reader);
        //         callback(reader.result)
        //     });
        //     reader.readAsDataURL(img);
        // };
        //
        // const handleChange = (info) => {
        //     console.log('handleChange', info);
        //     if (info.file.status === 'uploading') {
        //         setLoading(true);
        //         console.log('uploading');
        //         return;
        //     }
        //     if (info.file.status === 'done') {
        //         console.log('done', info.file);
        //         // Get this url from response in real world.
        //         getBase64(info.file.originFileObj, (url) => {
        //             setLoading(false);
        //             setImageUrl(url);
        //         });
        //     }
        // };

        const uploadImage = (file) => {
            setFetching(true);
            let bodyFormData = new FormData();
            bodyFormData.append('imageRequest', file);
            //bodyFormData.append('file_type', file_type);
            try {
                actionUploadImage(bodyFormData).then((res) => {
                    let imgUrl = res?.data?.[0] || null;
                    setImageUrl(imgUrl);
                    onUploadSuccess?.(imgUrl);
                    setFetching(false);
                });


                // actionUploadImage(bodyFormData).then(()=> {
                // setCropData(data?.data[0]);
                // onCropSuccess(data?.data[0]);
                // });
                //
                // if (+status === 200) {
                //     setCropData(data?.data[0]);
                //     onCropSuccess(data?.data[0]);
                // }
            } catch (error) {
                console.log(error);
                setFetching(false);
            }


        };

        return (
            <Upload
                className='upload-image-component'
                maxCount={1}
                listType='picture-card'
                showUploadList={false}
                accept='image/*'
                action={(file) => {
                    uploadImage(file);
                    return null;
                }}
                beforeUpload={beforeUpload}
            >
                <Spin spinning={isFetching}
                      indicator={<LoadingOutlined/>}
                      size='large'
                >
                    {/*{imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : <UploadOutlined />}*/}
                    {imageUrl ?
                        (<img src={imageUrl}/>) :
                        (<Button icon={<UploadOutlined/>}/>)
                    }
                </Spin>
            </Upload>


        )
    }

export default UploadImage;