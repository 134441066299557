import './css/index.scss';
import { Image } from 'antd'
import { convertDateTime } from '../../utils/common'
function TableContent(props) {
  const { record, fullWidth = false } = props
  return (
    <div className="table-content">
      {
        !['link', 'image', 'date'].includes(record?.unit) && <span>
          {record.content || 'Chưa có thông tin'}
        </span>
      }
      {
        ['link'].includes(record?.unit) && <a onClick={() => record.click()}>
          {record.content}
        </a>
      }
      {
        ['date'].includes(record?.unit) && <span>
          {convertDateTime(Number(record.content), 'DD/MM/YYYY') || record.content}
        </span>
      }
      {
        record?.unit === 'image' && <Image
          className={fullWidth ? 'w-100' : ''}
          src={record.content}
          height={fullWidth ? 300 : 56}
        />
      }
    </div>
  )
}

export default TableContent;