// System
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const UPDATE_CMS_INFO = "UPDATE_CMS_INFO";
export const SAVE_LIST_LANGUAGE = "SAVE_LIST_LANGUAGE";
export const SAVE_LIST_PRIVINCES = "SAVE_LIST_LANGUAGE";
export const SAVE_LABELS = "SAVE_LABELS";

//otp
export const SET_OTP_REFERENCE = "SET_OTP_REFERENCE";

// UserInfo
export const SET_LOGIN_INFO = "SET_LOGIN_INFO";