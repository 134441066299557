import {AppstoreOutlined} from '@ant-design/icons'
import {getRouterByName, getRoutersByMenu} from '../../utils/common'

import {Trans} from 'react-i18next';
import {
    AdminPanelSettingsOutlined,
    AutoGraphOutlined, BookOnline, BookOutlined,
    ContactsOutlined,
    NewspaperOutlined,
    ShoppingBagOutlined,
    SupervisorAccountOutlined,
    PersonOutline
} from "@mui/icons-material";
import {PERMISSIONS} from "../permissions";

export default {
    MENUS: [
        {
            icon: <AppstoreOutlined/>,
            label: <Trans i18nKey={"CATALOG_DATA"}/>,
            key: 'METADATA',
            name: getRoutersByMenu('METADATA')[0]?.name,
            children: getRoutersByMenu('METADATA'),
            permission_keys: [
                PERMISSIONS.SUPER_ADMIN,
                PERMISSIONS.ADMIN,
            ]
        },
        {
            icon: <PersonOutline/>,
            label: "User management",
            key: 'USER',
            name: getRoutersByMenu('USER')[0]?.name,
            children: getRoutersByMenu('USER'),
            permission_keys: [
                PERMISSIONS.SUPER_ADMIN,
                PERMISSIONS.ADMIN,
            ]
        },
        // {
        //     icon: <AdminPanelSettingsOutlined/>,
        //     label: <Trans i18nKey="ADMIN_MANAGEMENT"/>,
        //     key: 'SUPPER_ADMIN',
        //     name: getRouterByName('SUPPER_ADMIN'),
        //     permission_keys: [
        //         PERMISSIONS.SUPER_ADMIN,
        //     ]
        // },
        {
            icon: <AutoGraphOutlined/>,
            label: <Trans i18nKey={"CHECKIN_REPORT"}/>,
            key: 'CHECKIN',
            name: getRoutersByMenu('CHECKIN')[0]?.name,
            children: getRoutersByMenu('CHECKIN'),
        },

        {
            icon: <ShoppingBagOutlined/>,
            label: "Bran management",
            key: 'BRAN',
            name: getRoutersByMenu('BRAN')[0]?.name,
            children: getRoutersByMenu('BRAN'),
        },
        {
            icon: <BookOutlined/>,
            label: <Trans i18nKey={"CONTENT_MANAGEMENT"}/>,
            key: 'NEWSPAPER',
            name: getRoutersByMenu('NEWSPAPER')[0]?.name,
            children: getRoutersByMenu('NEWSPAPER'),
            permission_keys: [
                PERMISSIONS.SUPER_ADMIN,
                PERMISSIONS.ADMIN,
            ]
        },
        // {
        //     icon: <ContactsOutlined/>,
        //     label: "Contact management",
        //     key: 'CONTACT',
        //     name: getRouterByName('CONTACT'),
        //     permission_keys: [
        //         PERMISSIONS.SUPER_ADMIN,
        //         PERMISSIONS.ADMIN,
        //     ]
        // },
    ]
}
