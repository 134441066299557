import IconLogo from '../../assets/images/logos/cj_vina_logo.webp';
import './css/index.scss';
import {Image} from "antd";

function LogoApp() {
    return (
        <div className="logo-app w-full">
            <Image src={IconLogo} preview={false} width={"130px"}/>
        </div>
    )
}

export default LogoApp;