import React, {useEffect, useState} from "react";
import {CKEditor} from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

import {actionUploadImage} from './actions'
import './style.scss'

// ClassicEditor
//     .create( document.querySelector( '#editor' ), {
//         toolbar: [ 'heading', '|', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
//         heading: {
//             options: [
//                 { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
//                 { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
//                 { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
//             ]
//         }
//     } )
//     .catch( error => {
//         console.log( error );
//     } );

const Editor = ({initValue, onChange}) => {

    const [value, setValue] = useState(initValue || '');

    useEffect(()=>{
       if(initValue){
           setValue(initValue);
       }
    },[initValue])

    const handleChange = (v) => {
        setValue(v);
        onChange(v);
    }

    // sử dụng const function CKEditor bị lỗi
    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    loader.file.then(async (file) => {
                        try {
                            let bodyFormData = new FormData();
                            bodyFormData.append('imageRequest', file);

                            actionUploadImage(bodyFormData).then((res) => {
                                let imgUrl = res?.data;
                                resolve({default: imgUrl});
                            });
                            // const { status, data } = await contentService.upload_file(bodyForData);
                            // // console.log()
                            // if (status == 200) {
                            //     resolve({default: `${data?.data[0]}`});
                            // }

                        } catch (error) {
                            reject(error);
                        }
                    });
                });
            }
        };
    }

    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    return (
        <div className="App">
            <CKEditor
                onReady={editor => {
                    editor.ui.getEditableElement().parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                    );
                    // this.editor = editor;
                }}
                config={{
                    extraPlugins: [uploadPlugin],
                }}
                // config={{ckfinder: {
                //         // Upload the images to the server using the CKFinder QuickUpload command.
                //         uploadUrl: 'http://95.216.124.130:8082/image-service/auth/upload-image'
                //     }}}
                editor={DecoupledEditor}
                onChange={(event, editor) => {
                    handleChange(editor.getData());
                }}
                data={value}
            />
        </div>
    );
}

export default Editor;