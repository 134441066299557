import * as actionTypes from "../actionTypes";
import CONSTANTS from "../../config/constants";

const initialState = {
    locale: "en",
    isLoading: true,
    //cmsInfo: CONSTANTS.CMS_APPS[0],
    provinces: [],
    actionMessages: {}
};

const system = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_LANGUAGE:
            return {...state, locale: action.payload};
        case actionTypes.TOGGLE_LOADING:
            return {...state, isLoading: action.payload};
        case actionTypes.SAVE_LIST_PRIVINCES:
            return {...state, provinces: action.payload};
        case actionTypes.SAVE_LABELS:
            return {...state, actionMessages: action.payload};
        default:
            return state;
    }
};

export default system;
