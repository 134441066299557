const BACKEND_URL = process.env.REACT_APP_API_URL;
export default {
  LOGIN: `${BACKEND_URL}/api/auth/login`,
  GENERATE_OTP: `${BACKEND_URL}/api/auth/generate-otp`,
  REGENERATE_OTP: `${BACKEND_URL}/api/auth/regenerate-otp`,
  VERIFY_OTP: `${BACKEND_URL}/api/auth/verify-otp`,
  FORGOT_PASSWORD: `${BACKEND_URL}/api/auth/forgot-password`,
  LOGOUT: `${BACKEND_URL}/api/auth/logout`,
  GET_PROVICES:`${BACKEND_URL}/api/region/get-all-province`,
  GET_USER_LOGIN_INFO:`${BACKEND_URL}/api/auth/user-info`,
  USER_CHANGE_LOGIN:`${BACKEND_URL}/api/super-admin/disable-user`,
  USER_REMOVE:`${BACKEND_URL}/api/super-admin/remove-account`,
  EXPORT_NEW_REGISTER:`${BACKEND_URL}/api/super-admin/export-new-register`,

  GET_LABELS:`${BACKEND_URL}/api/label`,

  ADMIN_GET_ALL: `${BACKEND_URL}/api/super-admin/get-admins`,
  NEW_REGISTER_GET_REPORT: `${BACKEND_URL}/api/super-admin/view-new-register`,
  ADMIN_REGION_ADD_UPDATE: `${BACKEND_URL}/api/super-admin/create-or-edit-region-role`,
  ADMIN_DELETE: `${BACKEND_URL}/api/super-admin/remove-admin`,
  ADMIN_CHANGE_PASSWORD: `${BACKEND_URL}/api/super-admin/change-password`,
};
