import React from 'react';
import dayjs from 'dayjs';
import {IntlProvider} from 'react-intl';
import {ConfigProvider} from 'antd';
import {connect} from 'react-redux';
import vi_VN from 'antd/locale/vi_VN';
import en_US from 'antd/locale/en_US';
import 'dayjs/locale/en';
import vi from '../locale/vi.json';
import en from '../locale/en.json';

const Locales = (props) => {
    const localeIntl = {
        locale: props.locale,
        messages: props.locale === 'en' ? en : vi,
    };
    dayjs.locale('en');
    const CustomLocale = {
        ...en_US,
        dateFormat: 'MM/DD/YYYY',
        dateTimeFormat: 'MM/DD/YYYY HH:mm:ss',
    };
    return (
        <IntlProvider {...localeIntl}>
            <ConfigProvider locale={CustomLocale}>
                {props.children}
            </ConfigProvider>
        </IntlProvider>
    );
};

export default connect(
    (state) => ({
        locale: state.system.locale,
    }),
    null
)(Locales);
