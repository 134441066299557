import {message} from "antd";
import Cookies from "js-cookie";

export function handleResponseErrorMessage(err) {
    if (!err.response) {
        return err;
    } else if (+err.response.status === 401) {
        Cookies.remove("login_info");
        window.navigatePage("LOGIN");
        return err?.response?.data;
    } else if (+err.response.status === 405) {
        Cookies.remove("login_info");
        window.navigatePage("LOGIN");
        return err?.response?.data;
    } else if (+err.response.status === 403) {
        window.navigatePage('403')
        return err?.response?.data;
    } else if (+err.response.status === 500) {
        window.navigatePage('500');
        return err?.response?.data;
    } else if (+err.response.status === 502) {
        window.navigatePage('502');
        return err?.response?.data;
    } else {
        return err?.response?.data || {
            data: null,
            status: {
                label: 'System error, Please try again!',
                message: 'System error, Please try again!'
            }
        };
    }
}
