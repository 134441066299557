import {Button, Col, Form, Input, message, Modal, Row, Spin} from "antd";
import {PASSWORD_PATTERN} from "../../../config/patterns";
import adminServices from "../../../services/admin/user";
import authServices from "../../../services/auth"
import {INIT_ERROR} from "../../../config/constants";
import {useState} from "react";

const ModalChangePassword = (props) => {
    const {onCancel, onSuccess, username, open} = props;
    const [form] = Form.useForm();
    const [isProcessing, setProcessing] = useState(false);
    const handleSubmit = async (values) => {
        setProcessing(true)
        let data = {
            "username": username,
            "oldPassword": values?.oldPassword,
            "newPassword": values?.retypeNewPassword,
        }
        try {
            let res = await adminServices.changeAdminPassword(data);
            if (+res?.status?.code === 200) {
                message.success("Success");
                onSuccess();
                authServices.logout();
            }
        } catch (e) {
            message.error(e?.status?.label || INIT_ERROR);
            setProcessing(false);
        }
        setProcessing(false);
    }
    return (
        <Modal
            title="Change password"
            onCancel={onCancel}
            open={open}
            okText="Change password"
            okButtonProps={{className: "ant-btn-primary m-0 mt-m"}}
            onOk={() => form.submit()}
            cancelButtonProps={{style: {display: 'none'}}}
            destroyOnClose={true}
            confirmLoading={isProcessing}
        >
            <Spin spinning={isProcessing}>

                <Form
                    form={form}
                    onFinish={handleSubmit}
                    layout={"vertical"}
                >
                    <Form.Item
                        label="Current password"
                        name="oldPassword"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your current password',
                            },
                            {
                                pattern: PASSWORD_PATTERN,
                                message: "It should be longer than 6 characters"
                            }
                        ]}
                    >
                        <Input.Password placeholder="Current password"/>
                    </Form.Item>
                    <Form.Item
                        label="New password"
                        name="newPassword"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter new password',
                            },
                            {
                                pattern: PASSWORD_PATTERN,
                                message: "It should be longer than 6 characters"
                            }
                        ]}
                        hasFeedback
                    >
                        <Input.Password placeholder="New password"/>
                    </Form.Item>
                    <Form.Item
                        label="Retype new password"
                        name='retypeNewPassword'
                        dependencies={['newPassword']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please enter retype new password',
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('New password does not match'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder="Retype new password"/>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
}
export default ModalChangePassword;