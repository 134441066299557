import * as actionTypes from "../actionTypes";

const initialState = {
  loginInfo: null,
};

const userInfo = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOGIN_INFO:
      return { ...state, loginInfo: action.payload };
    default:
      return state;
  }
};

export default userInfo;
