import Cookies from "js-cookie";
import request from "../utils/request";
import ENDPOINT from "../config/endpoints";

function update_cms_Info(data) {
  Cookies.set("cms_info", JSON.stringify(data));
}

function cms_info() {
  try {
    return JSON.parse(Cookies.get("cms_info"));
  } catch (_) {
    return null;
  }
}

const getListProvinces = ()=>{
  return request({
    url: ENDPOINT.GET_PROVICES,
    method: "get",
  });
}

const getLabels = (params)=>{
  return request({
    url: ENDPOINT.GET_LABELS,
    method: "get",
    params
  });
}

export default {
  update_cms_Info,
  cms_info,
  getListProvinces,
  getLabels
};
