import React, {memo} from "react";
import './css/index.scss'
import {Button, Col, Row, Space} from 'antd'
import {useTranslation} from "react-i18next";

const AdminAction = (props) => {
    const {
        title = '',
        total = null,
        actions = []
    } = props;
    const {t} = useTranslation();
    return (
        <Row justify={"space-between"} className="admin-action">
            <Col></Col>
            <Col>
                <Space size={[8, 8]}>
                    {
                        actions.filter((a) => !a.hide).map((a) =>
                            <Button
                                key={a.key}
                                type={a.type}
                                disabled={a.disabled}
                                onClick={() => a.click()}
                                icon={a.icon}
                                ghost={a.ghost}
                                loading={a?.loading}
                            >
                                {a.label}
                            </Button>
                        )
                    }
                </Space>
            </Col>
        </Row>
    );
}

export default memo(AdminAction);