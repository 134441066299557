import CJVINA_ROUTERS from './cjvina'
import {PERMISSIONS} from "../permissions";
import {Trans} from "react-i18next";

export default {
    AUTH_ROUTERS: [
        {
            name: "LOGIN",
            path: "/auth/login",
            element: "Login",
            not_auth: true,
        },
        {
            name: "FORGET-PASSWORD",
            path: "/auth/forget-password",
            element: "ForgetPassword",
            not_auth: true,
        },
    ],

    ERROR_ROUTERS: [
        {
            name: "404",
            path: "/errors/404",
            element: "Error404",
        },
        {
            name: "500",
            path: "/errors/500",
            element: "Error500",
        },
    ],

    ADMIN_ROUTERS: [
        {
            name: "403",
            path: "/403",
            element: "Error403",
        },
        {
            name: "SUPPER_ADMIN",
            path: "/admin",
            element: "SupperAdminManagement",
            menu:"USER",
            label:"Admin",
            permission_keys: [
                PERMISSIONS.SUPER_ADMIN,
                PERMISSIONS.STAFF
            ]
        },
        ...CJVINA_ROUTERS.ROUTERS,
    ],
};

export const ROUTE_HIDE_BREADCRUMB = [
    "/"
];
