import moment from "moment";
import ROUTERS from "../config/routers";
import CONSTANTS, {ERR_CANCELED, INIT_ERROR} from "../config/constants";
import store from "../store";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import Jimp from "jimp";
import {useSelector} from "react-redux";
import {message} from "antd";
import POSTER from "../assets/images/antiseptic/poster.webp";
import jsPDF from "jspdf";
import {PERMISSIONS} from "../config/permissions";

const countryCodes = require('country-codes-list')

const has = Object.prototype.hasOwnProperty;
const allRouters = [
    ...ROUTERS.AUTH_ROUTERS,
    ...ROUTERS.ERROR_ROUTERS,
    ...ROUTERS.ADMIN_ROUTERS,
].map((r) => ({
    ...r,
    keys: r.path.split('/'),
}));

export const isDiff = (A, B) => JSON.stringify(A) !== JSON.stringify(B);

export const isEmpty = (prop) => {
    return (
        prop === null ||
        prop === undefined ||
        (has.call(prop, "length") && prop.length === 0) ||
        (prop.constructor === Object && Object.keys(prop).length === 0)
    );
};

export const formatNumber = (value = 0, fixed = 0) => {
    let newValue = value || 0;
    if (fixed && `${value}`.split(".")[1]?.length > fixed) {
        newValue = Number(value).toFixed(fixed);
    }
    return `${newValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatCurrency = (value = 0, fixed = 0) => {
    let newValue = value || 0;
    if (fixed && `${value}`.split(".")[1]?.length > fixed) {
        newValue = Number(value).toFixed(fixed);
    }
    return `${newValue} đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatMoneyCurrency = (value = 0, fixed = 0, currency = 'VND') => {
    return Intl.NumberFormat(undefined, {
        style: 'currency',
        currency,
        maximumFractionDigits: fixed
    }).format(value).replace(/^(\D+)/, '$1 ').replace(/\s+/, ' ');
};

export const separatorNumber = (value = 0) => {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
    );
}

export const convertQueryToString = (routerPath, query) => {
    if (typeof query === "object" && !isEmpty(query)) {
        const querys = [];
        Object.keys(query).forEach(key => {
            querys.push(`${key}=${![null, undefined].includes(query[key]) ? query[key] : ''}`)
        });
        return `${routerPath}?${querys.join("&")}`;
    }
    if (typeof query === "string") {
        return `${routerPath}${query}`;
    }
    return routerPath;
};

export const convertStringToQuery = (queryString) => {
    const querys = queryString.slice(1, queryString.length)?.split("&");
    const query = {};
    querys.forEach((q) => {
        const key = q?.split("=")[0];
        const value = q?.split("=")[1];
        query[key] = value;
    });
    return query;
};

export const convertGender = (gender) => {
    return CONSTANTS.GENDERS.find((g) => g.value === gender || g.value === 2)
};


export const convertTime = (time) => {
    const hours = Math.floor(Number(time) / 60);
    const minutes = Number(time) % 60;
    return `${hours}h${minutes}m`;
};

export const convertDateTime = (date, format = CONSTANTS.DATE_FORMAT) => {
    if (dayjs(date).isValid()) {
        return dayjs(date).format(format);
    }
    return null;
};

export const formatDateToTimestamp = (date, type = "") => {
    switch (type.toUpperCase()) {
        case 'START':
            return parseInt(dayjs(dayjs(date).format('MM/DD/YYYY 00:00')).valueOf());
        case 'END':
            return parseInt(dayjs(dayjs(date).format('MM/DD/YYYY 23:59')).valueOf());
        default:
            return parseInt(dayjs(dayjs(date).format('MM/DD/YYYY HH:mm')).valueOf());
    }
}
export const convertDateToString = (date, format = CONSTANTS.DATE_FORMAT) => {
    return dayjs(date).format(format);
}

export const disabledDate = (current) => {
    return (
        current &&
        moment(current).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")
    );
};

export const disabledTime = (now, condition = true, type = "hour") => {
    const range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };
    if (condition) {
        return {
            disabledHours: () =>
                type === "hour"
                    ? range(0, 23).filter((h) => h <= moment(now).hour())
                    : [],
            disabledMinutes: () =>
                type === "minute"
                    ? range(0, 59).filter((h) => h <= moment(now).minute)
                    : [],
            disabledSeconds: () =>
                type === "second"
                    ? range(0, 59).filter((h) => h <= moment(now).second)
                    : [],
        };
    }
    return {
        disabledHours: () => [],
    };
};

export const getCurrentWeekday = (date) => {
    return moment(date).weekday() + 2 > 7 ? 1 : moment(date).weekday() + 2;
};

export const randomColor = () => {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
};

export const convertDateNow = () => {
    const now = moment();
    const mOptions = [0, 15, 30, 45];
    const h = now.hours();
    const m = now.minutes();
    const lastM = mOptions.find((o) => o > m);
    if (lastM) {
        return moment(moment().format(`YYYY/MM/DD ${h}:${lastM}:ss`));
    } else {
        return moment(moment().add(1, "hours").format(`YYYY/MM/DD HH:00:ss`));
    }
};

export const getRouterParams = (routerPath, params) => {
    let path = routerPath;
    const keys = routerPath
        ?.split("/:")
        ?.map((p) => p?.split("/")[0])
        ?.filter(Boolean);
    keys?.forEach((key) => {
        path = path?.replace(`:${key}`, params[key]);
    });
    return path;
};

export const getRouterByName = (name) => {
    const route = allRouters.find((r) => r.name === name);
    return route
        ? {
            ...route,
            key: route.name,
        }
        : {};
};

export const getMenuByName = (name) => {
    const {MENUS} = require("../config/menus");
    return MENUS.find((m) => m.key === name);
};

export const compareLocation = (routerKeys, location) => {
    const locationKeys = location.pathname?.split('/');
    const result = {
        params: {},
        valid: true
    }
    if (locationKeys.length !== routerKeys.length) {
        result.valid = false;
    } else {
        routerKeys.forEach((k, index) => {
            if (k.includes(':')) {
                result.params = {
                    ...result.params,
                    [k.replace(':', '')]: locationKeys[index]
                }
            } else if (locationKeys[index] !== k) {
                result.valid = false
            }
        })
    }
    return result
}

export const getRouterByLocation = (location) => {
    const route = allRouters.find((r) => compareLocation(r.keys, location).valid);
    return route ? {
        ...route,
        key: route.name,
        params: compareLocation(route.keys, location).params,
        query: convertStringToQuery(location.search)
    } : null
}

export const getMenuByLocation = (location) => {
    const route = getRouterByLocation(location);
    if (route && route.menu) {
        return getMenuByName(route.menu);
    }
    const parent = allRouters.find((r) => r.name === route?.parent);
    return getMenuByName(parent?.menu);
};

export const getRoutersByMenu = (menu) => {
    return ROUTERS.ADMIN_ROUTERS.filter((r) => r.menu === menu && !r.parent).map((m) => ({
        ...m,
        key: m.name,
    }));
};

export const getRoutersByLocation = (location) => {
    const {MENUS} = require("../config/menus");
    const route = getRouterByLocation(location);
    const parents = [];
    let parent = allRouters.find((r) => r.name === route?.parent);
    while (parent) {
        parents.unshift(parent);
        parent = allRouters.find((r) => r.name === parent?.parent);
    }
    let menu = MENUS.find((m) => m.key === (parents[0]?.menu || route?.menu));
    let results = menu ? [menu] : []
    if (parents.length > 0) {
        return [...results, ...parents, route];
    }
    if (route) {
        return [...results, route];
    }
    return [getRouterByName("DASHBOARD")];
};

export const paymentStatus = (status) => {
    return CONSTANTS.PAYMENT_STATUS.find(e => e.value === status) || {};
};

export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
    });
}

export const convertImageToBase64PNG = (file) => {
    // Load the JPG image file
    Jimp.read(file)
        .then(image => {
            // Convert it to a PNG format
            image
                .write('output.png')
                .getBase64Async(Jimp.MIME_PNG)
                .then(base64 => {
                    // Print the base64 image
                    console.log(base64);
                })
                .catch(error => {
                    console.log(error);
                });
        })
        .catch(error => {
            console.log(error);
        });
}

export const appotaStatus = (status) => {
    return CONSTANTS.APPOTA_STATUS.find((s) => s.value === status)
}

export const isPermission = (keys) => {
    const loginInfo = store.getState().userInfo.loginInfo
    const userPermission = loginInfo?.user?.authorities || [];
    if (!keys || userPermission === 'SUPER_ADMIN') {
        return true
    }
    // const roleCategories = store.getState().userInfo.loginInfo?.user?.roleCategories || []
    // const allPermissions = roleCategories.map((c) => c.permissions).flat()
    // const choosePermissions = allPermissions.filter((p) => !!p.isChoose)
    let result = false
    // Loop through each element of b
    for (let authority of userPermission) {
        // Check if a includes the element
        if (keys?.includes(authority?.authority)) {
            result = true;
        }
    }
    return result
}

export const getSideBarMenus = () => {
    const {MENUS} = require("../config/menus");
    const loginInfo = store.getState().userInfo.loginInfo
    const userPermission = loginInfo?.user?.authorities;
    const regionCodes = loginInfo?.user?.regionCodes;

    let menus = []

    //loginInfo && loginInfo.customerId
    if (1) {
        menus = MENUS.map((m) => {
            if (m?.children) {
                return {
                    ...m,
                    children: m?.children.filter((c) => isPermission(c.permission_keys) && (userPermission?.findIndex(e => e?.authority === PERMISSIONS.MANAGER) !== -1 ? regionCodes?.findIndex(e => e?.role === m?.key) !== -1 : true))
                }
            }
            return {
                ...m,
                hide: !isPermission(m.permission_keys)
                //hide: false
            }
        }).filter((m) => {
            if (m.children) {
                return m.children.length > 0
            }
            return !m.hide
        })
    }
    return menus.map((m) => {
        delete m.hide
        return m
    })
}

export const getFirstMenu = () => {
    const sideBarMenus = getSideBarMenus()
    return sideBarMenus[0] ? (sideBarMenus[0].children ? sideBarMenus[0].children[0] : sideBarMenus[0]) : null
}

export const openNewPageByRoute = (routeName, params, query) => {
    let currentRouter = getRouterByName(routeName);
    let url = convertQueryToString(getRouterParams(currentRouter.path, params), query);
    window.open(`${window.location.origin}${url}`)
}

export const ConvertCopiedExcelDataToJson = (data) => {
    try {
        const workbook = XLSX.read(data, {type: 'string', raw: true});
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        // Assuming the worksheet is stored in a variable called worksheet
        // Get the reference range of the worksheet
        let ref = worksheet["!ref"]; // A1:C2

        // Split the reference range into start and end cell addresses
        let [start, end] = ref?.split(":");

        // Get the row and column numbers of the start and end cells
        let startRow = parseInt(start?.replace(/\D/g, ""));
        let startCol = start?.replace(/\d/g, "")?.charCodeAt(0) - 64;
        let endRow = parseInt(end?.replace(/\D/g, ""));
        let endCol = end?.replace(/\d/g, "")?.charCodeAt(0) - 64;

        // Initialize an empty array to store the result
        let result = [];

        // Loop through the roworksheet and columns of the worksheet
        for (let i = startRow; i <= endRow; i++) {
            // Initialize an empty array to store the current row
            let row = [];
            for (let j = startCol; j <= endCol; j++) {
                // Convert the column number to a letter
                let col = String.fromCharCode(j + 64);
                // Get the cell address
                let cell = col + i;
                // Get the cell value
                let value = worksheet[cell]?.t === "n" ? worksheet[cell]?.w : worksheet?.[cell]?.v;
                // Push the value to the current row
                row.push(value);
            }
            // Push the current row to the result array
            result.push(row);
        }
        result?.splice(0, 1);
        if (result?.length < 1) {
            return null;
        } else {
            return result?.map((subarray) => {
                return subarray.map((item) => {
                    return item?.replace(/\n/g, "") || ""; // return item if it is truthy, or "" otherwise
                });
            });
        }
        //convert to api body data //remove undefined //remove \n tag

    } catch (error) {
        console.error('Error converting Excel to JSON:', error);
        return null;
    }

    //Split all the text into seperate lines on new lines and carriage return feeds
    // let allTextLines = data?.split(/\r\n|\n/)?.filter(item => item !== ""); //filter remove item have ""
    // console.log('check all line', allTextLines)
    // return allTextLines?.map(item => item?.split("\t"))// conver arrays string to arrays[arrays]
}

export const PreviewExcelRawData = (data) => {
    try {
        const workbook = XLSX.read(data, {type: 'string', raw: true});
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        let x = XLSX.utils.sheet_to_json(worksheet, {range: 0, defval: "", raw: true});
        let columns = Object.keys(x[0])?.map(function (key) {
            return {dataIndex: key, key: key, title: key, width: '200px'};
        });
        columns[0].fixed = "left";
        columns[0].width = "80px"
        columns[0].align = "center";
        return {
            columns: columns,
            dataSource: x,
        }
    } catch (error) {
        console.error('Error converting Excel to JSON:', error);
        return null;
    }

    //Split all the text into seperate lines on new lines and carriage return feeds
    // let allTextLines = data?.split(/\r\n|\n/)?.filter(item => item !== ""); //filter remove item have ""
    // console.log('check all line', allTextLines)
    // return allTextLines?.map(item => item?.split("\t"))// conver arrays string to arrays[arrays]
}
export const DownloadExportFileFromServer = (response, filename) => {
    const url = URL.createObjectURL(response);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}

export const DownloadHTML = (elementId, fileName) => {
    const canvas = document.getElementById(elementId)?.querySelector('canvas');
    if (canvas) {
        const url = canvas.toDataURL();
        const a = document.createElement('a');
        a.download = fileName;
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
}

export const ACTION_MESSAGE = () => {
    return useSelector(state => state.system.actionMessages)
}

export const LOCATION_VIEW_ON_MAPS = (lat, lng) => {
    return window.open(`https://www.google.com/maps/search/${lat},${lng}?entry=tts`)
}

export const DOWNLOAD_SVG_AS_PNG = (elementId = "", fileName = "qr.png") => {
    return new Promise((resolve) => {
        const qrCode = document.getElementById(elementId)?.querySelector('svg');
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const qrImg = new Image();
        const xmlSerializer = new XMLSerializer();
        const svgString = xmlSerializer.serializeToString(qrCode);
        const base64String = btoa(unescape(encodeURIComponent(svgString)));
        qrImg.src = "data:image/svg+xml;base64," + base64String;
        qrImg.onload = () => {
            canvas.width = 2000;
            canvas.height = 2000;
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, 2000, 2000)
            ctx.drawImage(qrImg, 40, 40, 1920, 1920);
            // let reader_1 = new FileReader();
            // let downloadURL = reader_1.readAsDataURL(result);
            let filename = fileName;
            let downloadURL = canvas.toDataURL('image/jpeg', 1.0);
            const a = document.createElement('a');
            a.download = filename;
            a.href = downloadURL;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            resolve(true)
        }
    });
}

export const FIX_FILENAME = (filename = "") => {
    return filename?.replace(/ _+/g, "_").replaceAll("undefined", "")
}

export const isError = (e) => {
    if (e?.code !== ERR_CANCELED) {
        message.error(e?.status?.label || INIT_ERROR).then(r => null);
        console.log(e);
    }
}