import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import LocaleProvider from "./utils/provider";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import i18n from './locale/_i18n';
import { I18nextProvider } from 'react-i18next';

const root = ReactDOM.createRoot(document.getElementById("app"));
root.render(
  <React.Fragment>
    <Provider store={store}>
      <LocaleProvider>
        <BrowserRouter>
            <I18nextProvider i18n={i18n}>
                <App />
            </I18nextProvider>
        </BrowserRouter>
      </LocaleProvider>
    </Provider>
  </React.Fragment>
);

reportWebVitals();
