import request from "../../utils/request";

const BACKEND_URL = process.env.REACT_APP_API_URL;


export const actionUploadImage = (bodyFormData) => {
    return request({
        method: 'post',
        url: `${BACKEND_URL}/api/news/upload-image`,
        data: bodyFormData
    })
}