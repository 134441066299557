import React, {lazy, useEffect, useMemo, useState} from 'react';
import {Layout, message, Spin} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import './assets/css/index.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-phone-number-input/style.css'

import {navigatePage} from "./utils/navigate";
import {getFirstMenu, getRouterByLocation, isEmpty, isPermission} from "./utils/common";

//import AdminLayout from "./layouts/admin"
//import AuthLayout from "./layouts/auth"
//import ErrorsLayout from "./layouts/errors"

import userServices from "./services/admin/user";
import authServices from "./services/auth";
import systemServices from "./services/system";

import {actionUpdateLoginInfo} from "./store/actions/userInfo";
import {actionSaveLabels, actionSaveListProvinces, actionUpdateCmsInfo} from "./store/actions/system";
import {LogoApp} from "./components";
import {INIT_ERROR} from "./config/constants";
import userInfo from "./store/reducers/userInfo";
import {PERMISSIONS} from "./config/permissions";

const AdminLayout = lazy(() => import("./layouts/admin"));
const AuthLayout = lazy(() => import("./layouts/auth"));
const ErrorsLayout = lazy(() => import("./layouts/errors"));

function App() {
    const navigate = useNavigate();
    window.navigatePage = (name, params = {}, query = {}) => navigatePage(navigate, name, params, query);

    const loginInfo = authServices.login_info();
    const userInfo = useSelector(state => state.userInfo?.loginInfo);
    const location = useLocation();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true)

    // useEffect(() => {
    //     fetchActionMessage();
    // }, [])
    useEffect(() => {
        if (loginInfo && loginInfo?.token && loginInfo?.isAccessCms) {
            fetchData();
        }else {
            setLoading(false);
        }
    }, [])

    useEffect(() => {
        const currentPage = getRouterByLocation(location);
        if (loginInfo && loginInfo?.token && loginInfo?.isAccessCms) {
            if (!currentPage || location.pathname.includes("/auth/login")) {
                if (!userInfo?.user) {
                    fetchData();
                } else {
                    navToPage(userInfo);
                    setLoading(false);
                }
            }
        } else {
            if (!location.pathname.includes('/auth/login')) {
                window.navigatePage('LOGIN');
            }
        }
    }, [loginInfo, location]);

    const navToPage = (data) => {
        const hasManagerPermission = data?.user?.authorities?.some(e => e?.authority === PERMISSIONS.MANAGER);
        const hasCheckinRole = data?.user?.regionCodes?.some(e => e?.role === "CHECKIN");
        if (!hasManagerPermission) {
            window.navigatePage('CHECKIN_HISTORIES');
        } else if (!hasCheckinRole) {
            window.navigatePage("BRAN_SCAN_HISTORY");
        } else {
            window.navigatePage('CHECKIN_HISTORIES');
        }
    }

    const fetchData = async () => {
        setLoading(true)
        try {
            let res = await userServices?.getUserLoginInfo();
            if (+res?.status?.code === 200 && res?.data?.isAccessCms) {
                let newLoginInfo = {
                    ...loginInfo,
                    user: res.data || {}
                }
                dispatch(actionUpdateLoginInfo(newLoginInfo));
                await fetchProvinces();
                navToPage(newLoginInfo);
                setLoading(false)
            } else {
                dispatch(actionUpdateLoginInfo(null))
                setLoading(false);
                window.navigatePage("LOGIN");
            }
        } catch (e) {
            message.error(e?.status?.label || INIT_ERROR);
            window.navigatePage("LOGIN");
            setLoading(false);
        }
    }

    const fetchProvinces = async () => {
        try {
            let res = await systemServices.getListProvinces();
            if (+res?.status?.code === 200) {
                dispatch(actionSaveListProvinces(res?.data));
            }
        } catch (e) {
            message.error(e?.status?.label || INIT_ERROR);
        }
    }

    const layoutType = useMemo(() => {
        if (location.pathname.includes('/errors/')) {
            return 'errors'
        }
        if (!isEmpty(loginInfo)) {
            return 'admin'
        }
        return 'auth'
    }, [location, loginInfo]);

    return (
        <Spin spinning={loading}>
            <Layout>
                {
                    layoutType === 'admin' && <AdminLayout/>
                }
                {
                    layoutType === 'auth' && <AuthLayout/>
                }
                {
                    layoutType === 'errors' && <ErrorsLayout/>
                }
            </Layout>
        </Spin>
    );
}

export default App;
