import request from "../../utils/request";
import ENDPOINT from "../../config/endpoints";

const getAllAdmin = (params, controller) => {
    return request({
        url: ENDPOINT.ADMIN_GET_ALL,
        method: "get",
        params,
        signal: controller?.signal
    });
}

const getNewRegisterReport = (params, controller) => {
    return request({
        url: ENDPOINT.NEW_REGISTER_GET_REPORT,
        method: "get",
        params,
        signal: controller?.signal
    });
}
const deleteAdmin = (data) => {
    return request({
        url: ENDPOINT.ADMIN_DELETE,
        method: "put",
        data
    });
}

const getUserLoginInfo = () => {
    return request({
        url: ENDPOINT.GET_USER_LOGIN_INFO,
        method: "get",
    });
}

const addOrUpdateAdminRegionRole = (data) => {
    return request({
        url: ENDPOINT.ADMIN_REGION_ADD_UPDATE,
        method: "put",
        data
    });
}

const changeAdminPassword = (data) => {
    return request({
        url: ENDPOINT.ADMIN_CHANGE_PASSWORD,
        method: "post",
        data
    });
}
const changeLoginPermission = (params) => {
    return request({
        url: ENDPOINT.USER_CHANGE_LOGIN,
        method: "post",
        params
    });
}

const removeAccount = (id) => {
    return request({
        url: ENDPOINT.USER_REMOVE,
        method: "delete",
        params: {
            userId: id,
        }
    });
}

const exportNewRegister = (params) => {
    return request({
        url: ENDPOINT.EXPORT_NEW_REGISTER,
        method: "get",
        params,
        responseType: 'blob'
    });
}

export default {
    getAllAdmin,
    getNewRegisterReport,
    addOrUpdateAdminRegionRole,
    deleteAdmin,
    getUserLoginInfo,
    changeAdminPassword,
    changeLoginPermission,
    removeAccount,
    exportNewRegister,
};
