import {Trans} from "react-i18next";

export const GG_MAP_API = process.env.GOOGLE_MAP_API_KEY;

export default {
    DATE_FORMAT: "MM/DD/YYYY",
    DATE_TIME_FORMAT: "MM/DD/YYYY HH:mm:ss",
};

export const DATE_TIME_FORMAT = "MM/DD/YYYY HH:mm:ss";
export const DATE_FORMAT = "MM/DD/YYYY";

export const ACCOUNT_STATE = {
    GENERATE_OTP: "generate",
    VERIFY_OTP: "otp",
    REGISTER: "register",
    RESET_PWD: "reset-pwd",
    LOGIN_FORM: "login-form",
};

export const NEWSPAYPER_LANGUAGE = {
    vi: "Tiếng Việt",
    en: "English",
    ko: "Korean"
}
export const INIT_ERROR = <Trans i18nKey={"INIT_ERROR"}/>
export const ACTION_MESSAGE = {
    200: "Success",
}
export const LOCALES = [
    {
        "id": 1,
        "countryName": <Trans i18nKey={"VIETNAMESE"}/>,
        "countryCode": "vi",
        "imageUrl": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Flag_of_Vietnam.svg/2560px-Flag_of_Vietnam.svg.png",
        "active": true
    },
    {
        "id": 3,
        "countryName": <Trans i18nKey={"ENGLISH"}/>,
        "countryCode": "en",
        "imageUrl": "https://media.istockphoto.com/vectors/flagunited-kingdom-flag-vector-id1211686337?k=20&m=1211686337&s=612x612&w=0&h=irewSfrd_QbxxmNWMpqpHrdf0pBVjnKoOJnkvonjz3g=",
        "active": true
    },
];

export const CATEGORY_TYPE = [
    {
        type: "CHECKIN",
        name: <Trans i18nKey={"CHECKIN"}/>
    },
    {
        type: "SCAN_BRAN",
        name: <Trans i18nKey={"SCAN_BRAN"}/>
    },
    {
        type: "OTHER",
        name: <Trans i18nKey={"OTHER"}/>
    },
]

export const POINT_TYPES = [
    {
        key: "XIT_MAY",
        name: "Xịt máy"
    },
    {
        key: "XIT_TAY",
        name: "Xịt tay"
    },
    {
        key: "TRUNG_CHUYEN",
        name: "Trung chuyển"
    },
    {
        key: "BAI_XE",
        name: "Bãi xe"
    }
]

export const SCAN_BRAN_PERMISSION = [
    {
        key: true,
        name: <Trans i18nKey={"ALLOW"}/>
    },
    {
        key: false,
        name: <Trans i18nKey={"DENIED"}/>
    },
]

export const GENDER = [
    {
        key: 0,
        name: <Trans i18nKey={"FEMALE"}/>
    },
    {
        key: 1,
        name: <Trans i18nKey={"MALE"}/>
    },
    // {
    //     key: "",
    //     name: <Trans i18nKey={"OTHER"}/>
    // },
]

export const SIMPLE_STATUS = [
    {
        key: true,
        name: "Active"
    },
    {
        key: false,
        name: "Inactive"
    },
]

export const WORKING_STATUS = [
    {
        key: true,
        name: <Trans i18nKey={"WORKING"}/>
    },
    {
        key: false,
        name: <Trans i18nKey={"QUITTING"}/>
    },
]

export const POINT_STATUS = [
    {
        key: true,
        name: "Active"
    },
    {
        key: false,
        name: "Liquidate"
    }
]

export const BRAN_TYPES = [
    {
        key: "NORMAL",
        name: "Normal",
    },
    {
        key: "SILO",
        name: "Silo",
    }
]
export const BRAN_STATUS = [
    {
        key: true,
        name: "Using",
    },
    {
        key: false,
        name: "Disusing",
    }
]

export const FARM_TYPES = [
    {
        key: "contract_farm",
        name: "Contract farm"
    },
    {
        key: "hire_farm",
        name: "Hire farm"
    }
]

export const USER_TYPES = [
    {
        key: "STAFF",
        name: "Staff"
    }, {
        key: "VISITOR",
        name: "Visitor"
    },
]

export const PERMISSION_KEYS = [
    {
        key: true,
        name: 'Allow'
    }, {
        key: false,
        name: "Denied"
    }
]
export const DELETION_STATUS = [
    {
        key: false,
        name: "Normal"
    },
    {
        key: true,
        name: 'Deleted'
    },
]

export const EPIDEMIC_WARNING = [
    {
        key: 0,
        name: "Normal",
        color: "#52c41a"
    },
    {
        key: 1,
        name: "Level 1 epidemic warning",
        color: '#f2cd03'
    },
    {
        key: 2,
        name: "Level 2 epidemic warning",
        color: "#fa8c16"
    },
    {
        key: 3,
        name: "Epidemic pressure",
        color: "red"
    },
    {
        key: 4,
        name: "PED",
        color: "#ab00ff"
    },
]

export const MANAGER_VIEW_ROLES = {
    "CHECKIN": "CHECKIN",
    "BRAN": "BRAN",
}

export const ERR_CANCELED = "ERR_CANCELED"